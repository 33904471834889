<template>
  <v-container class="ma-0 pa-0" fluid></v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",

  data() {
    return {};
  },

  computed: {
    ...mapState("users", ["status"]),
  },

  created() {
    if (this.status.loggedIn) this.$router.push("/dashboard");
    else this.$router.push("/login");
  },
};
</script>
